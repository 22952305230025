import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'YDL6Fp',
    defaultMessage: 'Personalized Plan',
    description: 'Subscription card: title',
  },
  expiryDate: {
    id: 'xAopHN',
    defaultMessage: 'Expires on:',
    description: 'Subscription expiry date label',
  },
  expiredDate: {
    id: '5uDHtm',
    defaultMessage: 'Expired on:',
    description: 'Subscription expiry date label',
  },
  billingPeriod: {
    id: 'QJAzLO',
    defaultMessage: 'Billing period:',
    description: 'Subscription billing period label',
  },
  nextChargeDate: {
    id: 'QCNVYS',
    defaultMessage: 'Next charge date:',
    description: 'Subscription next charge date label',
  },
  price: {
    id: 'i6wJo4',
    defaultMessage: 'Price:',
    description: 'Subscription price label',
  },
  cancelRenewal: {
    id: '6iT1A8',
    defaultMessage: 'Cancel renewal',
    description: 'Cancel renewal button',
  },
  'subscriptions.plan.weeks': {
    id: '4koaxQ',
    defaultMessage: '{count,plural,=0{} one{#-week plan} other{#-week plan}}',
    description: 'Plan: Week',
  },
  'subscriptions.plan.months': {
    id: 'wxuofS',
    defaultMessage: '{count,plural,=0{} one{#-month plan} other{#-month plan}}',
    description: 'Plan: Month',
  },
  'subscriptions.plan.year': {
    id: 'umRnfQ',
    defaultMessage: '{count,plural,=0{} one{#-year plan} other{#-year plan}}',
    description: 'Plan: Year',
  },
  'subscriptions.billing.weeks': {
    id: 'z8Cdv1',
    defaultMessage: '{count,plural,=0{} one{# week} other{# week}}',
    description: 'Plan: Week',
  },
  'subscriptions.billing.months': {
    id: 'FExNCq',
    defaultMessage: '{count,plural,=0{} one{# month} other{# month}}',
    description: 'billing: Month',
  },
  'subscriptions.billing.year': {
    id: 'mQppLq',
    defaultMessage: '{count,plural,=0{} one{# year} other{# year}}',
    description: 'Plan: Year',
  },
  'subscriptions.cancelledHint.title': {
    id: 'pXwbyq',
    defaultMessage: 'Your subscription has been cancelled',
    description: 'cancelled subscription hint',
  },
  'subscriptions.cancelledHint.message': {
    id: 'QWEx9f',
    defaultMessage:
      'Though, you will be able to access Mindea during your already paid subscription period - until {expiryDate}',
    description: 'cancelled subscription hint',
  },
  'subscriptions.empty': {
    id: 'JTHpnr',
    defaultMessage: "You don't have an active subscription",
    description: 'no subscription message',
  },
});
