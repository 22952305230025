/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';

import { useHttpClient } from '../../useHttpClient';

import type { ErrorType } from '../../useHttpClient';
import type {
  Userscancelsubscriptionmodel400response,
  Userscancelsubscriptionmodelresponse,
  Userschangepasswordmodel400response,
  Userschangepasswordmodelrequest,
  Userschangepasswordmodelresponse,
  Usersfunnelprofilemodel400response,
  Usersfunnelprofilemodel404response,
  Usersfunnelprofilemodelresponse,
  Usersgetdeeplinkmodel400response,
  Usersgetdeeplinkmodelresponse,
  Userslocationmodel400response,
  Userslocationmodelresponse,
  Usersresetpasswordmodel400response,
  Usersresetpasswordmodelrequest,
  Usersresetpasswordmodelresponse,
  Userssigninmodel400response,
  Userssigninmodelrequest,
  Userssigninmodelresponse,
  Userssubscriptiongetmodel400response,
  Userssubscriptiongetmodelresponse,
  Usersupdateprofilemodel400response,
  Usersupdateprofilemodel404response,
  Usersupdateprofilemodelrequest,
  Usersupdateprofilemodelresponse,
} from '../model';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export const useGetLocationHook = () => {
  const getLocation = useHttpClient<Userslocationmodelresponse>();

  return (signal?: AbortSignal) => {
    return getLocation({ url: `/location`, method: 'GET', signal });
  };
};

export const getGetLocationQueryKey = () => {
  return [`/location`] as const;
};

export const useGetLocationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
  TError = ErrorType<Userslocationmodel400response>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLocationQueryKey();

  const getLocation = useGetLocationHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>> = ({
    signal,
  }) => getLocation(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLocationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>
>;
export type GetLocationQueryError = ErrorType<Userslocationmodel400response>;

export const useGetLocation = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
  TError = ErrorType<Userslocationmodel400response>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLocationHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLocationQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUsersFunnelProfileHook = () => {
  const getUsersFunnelProfile = useHttpClient<Usersfunnelprofilemodelresponse>();

  return (signal?: AbortSignal) => {
    return getUsersFunnelProfile({ url: `/users/funnel_profile`, method: 'GET', signal });
  };
};

export const getGetUsersFunnelProfileQueryKey = () => {
  return [`/users/funnel_profile`] as const;
};

export const useGetUsersFunnelProfileQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
  TError = ErrorType<Usersfunnelprofilemodel400response | Usersfunnelprofilemodel404response>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersFunnelProfileQueryKey();

  const getUsersFunnelProfile = useGetUsersFunnelProfileHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>
  > = ({ signal }) => getUsersFunnelProfile(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersFunnelProfileQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>
>;
export type GetUsersFunnelProfileQueryError = ErrorType<
  Usersfunnelprofilemodel400response | Usersfunnelprofilemodel404response
>;

export const useGetUsersFunnelProfile = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
  TError = ErrorType<Usersfunnelprofilemodel400response | Usersfunnelprofilemodel404response>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUsersFunnelProfileHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUsersFunnelProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostUsersFunnelProfileHook = () => {
  const postUsersFunnelProfile = useHttpClient<Usersupdateprofilemodelresponse>();

  return (usersupdateprofilemodelrequest: Usersupdateprofilemodelrequest) => {
    return postUsersFunnelProfile({
      url: `/users/funnel_profile`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: usersupdateprofilemodelrequest,
    });
  };
};

export const usePostUsersFunnelProfileMutationOptions = <
  TError = ErrorType<Usersupdateprofilemodel400response | Usersupdateprofilemodel404response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUsersFunnelProfileHook>>>,
    TError,
    { data: Usersupdateprofilemodelrequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostUsersFunnelProfileHook>>>,
  TError,
  { data: Usersupdateprofilemodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postUsersFunnelProfile = usePostUsersFunnelProfileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostUsersFunnelProfileHook>>>,
    { data: Usersupdateprofilemodelrequest }
  > = (props) => {
    const { data } = props ?? {};

    return postUsersFunnelProfile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUsersFunnelProfileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostUsersFunnelProfileHook>>>
>;
export type PostUsersFunnelProfileMutationBody = Usersupdateprofilemodelrequest;
export type PostUsersFunnelProfileMutationError = ErrorType<
  Usersupdateprofilemodel400response | Usersupdateprofilemodel404response
>;

export const usePostUsersFunnelProfile = <
  TError = ErrorType<Usersupdateprofilemodel400response | Usersupdateprofilemodel404response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUsersFunnelProfileHook>>>,
    TError,
    { data: Usersupdateprofilemodelrequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostUsersFunnelProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usePostResetPasswordHook = () => {
  const postResetPassword = useHttpClient<Usersresetpasswordmodelresponse>();

  return (usersresetpasswordmodelrequest: Usersresetpasswordmodelrequest) => {
    return postResetPassword({
      url: `/reset_password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: usersresetpasswordmodelrequest,
    });
  };
};

export const usePostResetPasswordMutationOptions = <
  TError = ErrorType<Usersresetpasswordmodel400response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>,
    TError,
    { data: Usersresetpasswordmodelrequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>,
  TError,
  { data: Usersresetpasswordmodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postResetPassword = usePostResetPasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>,
    { data: Usersresetpasswordmodelrequest }
  > = (props) => {
    const { data } = props ?? {};

    return postResetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>
>;
export type PostResetPasswordMutationBody = Usersresetpasswordmodelrequest;
export type PostResetPasswordMutationError = ErrorType<Usersresetpasswordmodel400response>;

export const usePostResetPassword = <
  TError = ErrorType<Usersresetpasswordmodel400response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostResetPasswordHook>>>,
    TError,
    { data: Usersresetpasswordmodelrequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetUsersHook = () => {
  const getUsers = useHttpClient<Usersgetdeeplinkmodelresponse>();

  return (signal?: AbortSignal) => {
    return getUsers({ url: `/users`, method: 'GET', signal });
  };
};

export const getGetUsersQueryKey = () => {
  return [`/users`] as const;
};

export const useGetUsersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>,
  TError = ErrorType<Usersgetdeeplinkmodel400response>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey();

  const getUsers = useGetUsersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>> = ({
    signal,
  }) => getUsers(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>
>;
export type GetUsersQueryError = ErrorType<Usersgetdeeplinkmodel400response>;

export const useGetUsers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>,
  TError = ErrorType<Usersgetdeeplinkmodel400response>,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUsersHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostLoginHook = () => {
  const postLogin = useHttpClient<Userssigninmodelresponse>();

  return (userssigninmodelrequest: Userssigninmodelrequest) => {
    return postLogin({
      url: `/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userssigninmodelrequest,
    });
  };
};

export const usePostLoginMutationOptions = <
  TError = ErrorType<Userssigninmodel400response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>,
    TError,
    { data: Userssigninmodelrequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>,
  TError,
  { data: Userssigninmodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postLogin = usePostLoginHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>,
    { data: Userssigninmodelrequest }
  > = (props) => {
    const { data } = props ?? {};

    return postLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLoginMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>
>;
export type PostLoginMutationBody = Userssigninmodelrequest;
export type PostLoginMutationError = ErrorType<Userssigninmodel400response>;

export const usePostLogin = <
  TError = ErrorType<Userssigninmodel400response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>,
    TError,
    { data: Userssigninmodelrequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetUserSubscriptionHook = () => {
  const getUserSubscription = useHttpClient<Userssubscriptiongetmodelresponse>();

  return (signal?: AbortSignal) => {
    return getUserSubscription({ url: `/user/subscription`, method: 'GET', signal });
  };
};

export const getGetUserSubscriptionQueryKey = () => {
  return [`/user/subscription`] as const;
};

export const useGetUserSubscriptionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserSubscriptionHook>>>,
  TError = ErrorType<Userssubscriptiongetmodel400response>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUserSubscriptionHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserSubscriptionQueryKey();

  const getUserSubscription = useGetUserSubscriptionHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserSubscriptionHook>>>
  > = ({ signal }) => getUserSubscription(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUserSubscriptionHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserSubscriptionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserSubscriptionHook>>>
>;
export type GetUserSubscriptionQueryError = ErrorType<Userssubscriptiongetmodel400response>;

export const useGetUserSubscription = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserSubscriptionHook>>>,
  TError = ErrorType<Userssubscriptiongetmodel400response>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUserSubscriptionHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserSubscriptionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostUserSubscriptionCancelHook = () => {
  const postUserSubscriptionCancel = useHttpClient<Userscancelsubscriptionmodelresponse>();

  return () => {
    return postUserSubscriptionCancel({ url: `/user/subscription/cancel`, method: 'POST' });
  };
};

export const usePostUserSubscriptionCancelMutationOptions = <
  TError = ErrorType<Userscancelsubscriptionmodel400response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserSubscriptionCancelHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostUserSubscriptionCancelHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postUserSubscriptionCancel = usePostUserSubscriptionCancelHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostUserSubscriptionCancelHook>>>,
    void
  > = () => {
    return postUserSubscriptionCancel();
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUserSubscriptionCancelMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostUserSubscriptionCancelHook>>>
>;

export type PostUserSubscriptionCancelMutationError =
  ErrorType<Userscancelsubscriptionmodel400response>;

export const usePostUserSubscriptionCancel = <
  TError = ErrorType<Userscancelsubscriptionmodel400response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserSubscriptionCancelHook>>>,
    TError,
    void,
    TContext
  >;
}) => {
  const mutationOptions = usePostUserSubscriptionCancelMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usePostChangePasswordHook = () => {
  const postChangePassword = useHttpClient<Userschangepasswordmodelresponse>();

  return (userschangepasswordmodelrequest: Userschangepasswordmodelrequest) => {
    return postChangePassword({
      url: `/change_password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userschangepasswordmodelrequest,
    });
  };
};

export const usePostChangePasswordMutationOptions = <
  TError = ErrorType<Userschangepasswordmodel400response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostChangePasswordHook>>>,
    TError,
    { data: Userschangepasswordmodelrequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostChangePasswordHook>>>,
  TError,
  { data: Userschangepasswordmodelrequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postChangePassword = usePostChangePasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostChangePasswordHook>>>,
    { data: Userschangepasswordmodelrequest }
  > = (props) => {
    const { data } = props ?? {};

    return postChangePassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostChangePasswordHook>>>
>;
export type PostChangePasswordMutationBody = Userschangepasswordmodelrequest;
export type PostChangePasswordMutationError = ErrorType<Userschangepasswordmodel400response>;

export const usePostChangePassword = <
  TError = ErrorType<Userschangepasswordmodel400response>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostChangePasswordHook>>>,
    TError,
    { data: Userschangepasswordmodelrequest },
    TContext
  >;
}) => {
  const mutationOptions = usePostChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
