export const ROUTES = {
  INSTALL_APP: '/',
  ANDROID_DISCLAIMER: '/android-disclaimer',
  HOME: '/',
  ACCOUNT: '/account',
  ACCOUNT_SETTINGS: '/account/settings',
  ACCOUNT_SUBSCRIPTIONS: '/account/subscriptions',
  ACCOUNT_PROFILE: '/account/profile',
  CHANGE_EMAIL: '/change-email',
  CHANGE_PASSWORD: '/change-password',
  RESET_PASSWORD: '/reset-password',
  LOG_IN: '/login',
};
