import React, { lazy, LazyExoticComponent, JSX, ReactNode } from 'react';

import { AccountProfileSkeleton } from 'pages/account-profile/components/AccountProfileSkeleton';
import { SubscriptionCardSkeleton } from 'pages/account-subscriptions/components/SubscriptionCardSkeleton';
import { ChangeEmailSkeleton } from 'pages/change-email/ChangeEmailSkeleton';
import { ChangePasswordSkeleton } from 'pages/change-password/ChangePasswordSkeleton';

import { ROUTES } from './routes';

const AndroidDisclaimer = lazy(() => import('pages/android-disclaimer'));
const ResetPassword = lazy(() => import('pages/reset-password'));
const ThankYouPage = lazy(() => import('pages/install-app'));
const Account = lazy(() => import('pages/account'));
const LogIn = lazy(() => import('pages/log-in'));
const AccountProfile = lazy(() => import('pages/account-profile'));
const AccountSubscriptions = lazy(() => import('pages/account-subscriptions'));
const AccountSettings = lazy(() => import('pages/account-settings'));
const ChangeEmail = lazy(() => import('pages/change-email'));
const ChangePassword = lazy(() => import('pages/change-password'));

export type RouteConfig = {
  url: string;
  Component: LazyExoticComponent<({ children }: any) => JSX.Element>;
  isPrivate?: boolean;
  children?: Array<Omit<RouteConfig, 'children'>>;
  fallback?: ReactNode;
};

export const PUBLIC_ROUTES: Record<string, RouteConfig> = {
  RESET_PASSWORD: {
    url: ROUTES.RESET_PASSWORD,
    Component: ResetPassword,
  },

  LOG_IN: {
    url: ROUTES.LOG_IN,
    Component: LogIn,
  },
} as const;

export const PRIVATE_ROUTES: Record<string, RouteConfig> = {
  INSTALL_APP: {
    // url: '/install-app',
    // while there is no cabinet functionality - show install app page as home
    url: ROUTES.INSTALL_APP,
    Component: ThankYouPage,
    isPrivate: true,
  },

  ANDROID_DISCLAIMER: {
    url: ROUTES.ANDROID_DISCLAIMER,
    Component: AndroidDisclaimer,
    isPrivate: true,
  },

  // HOME: {
  //   url: ROUTES.HOME,
  // },
  ACCOUNT: {
    url: ROUTES.ACCOUNT,
    Component: Account,
    isPrivate: true,
    children: [
      {
        url: ROUTES.ACCOUNT_SETTINGS,
        Component: AccountSettings,
      },

      {
        url: ROUTES.ACCOUNT_SUBSCRIPTIONS,
        Component: AccountSubscriptions,
        fallback: <SubscriptionCardSkeleton />,
      },

      {
        url: ROUTES.ACCOUNT_PROFILE,
        Component: AccountProfile,
        fallback: <AccountProfileSkeleton />,
      },
    ],
  },

  CHANGE_EMAIL: {
    url: ROUTES.CHANGE_EMAIL,
    Component: ChangeEmail,
    isPrivate: true,
    fallback: <ChangeEmailSkeleton />,
  },
  CHANGE_PASSWORD: {
    url: ROUTES.CHANGE_PASSWORD,
    Component: ChangePassword,
    isPrivate: true,
    fallback: <ChangePasswordSkeleton />,
  },
} as const;

export const ROUTES_CONFIG = Object.values({
  ...PRIVATE_ROUTES,
  ...PUBLIC_ROUTES,
} as const);
